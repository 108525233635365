@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&family=Josefin+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');

@font-face {
  font-family: 'YoungSerif';
  /* You can name it whatever you want */
  src: url('../public/fonts/YoungSerif-Regular.otf');
  /* Add other formats if you have them */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Queenside';
  /* You can name it whatever you want */
  src: url('../public/fonts/QueensidesMedium-x30zV.ttf');
  /* Add other formats if you have them */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HighSpeed';
  src: url("../public/fonts/HIGHSPEED.TTF");
}



a {
  color: inherit;
  text-decoration: none;
}

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
  background-color: #cccccc;
  /* or any other font you prefer */
}

#root {
  display: flex;
  height: 100%;
}

.navbar {
  flex: 1;
  z-index: 100;
  /* takes up 1/3 of the space */
  /* background-color: rgba(157, 157, 157, .8); */
  background-image: url('../public/back-image.png');
  /* flip background image horizontally */
  /* transform: scaleX(-1); */
  background-size: cover;
  /* or any color you prefer */
  color: white;
  box-shadow: 8px 0 10px -2px rgba(0, 0, 0, 0.4);
  /* text color */
  /* padding: 20px; */
  overflow-y: hidden;
  /* in case the navbar content exceeds the viewport height */
}

.content {
  flex: 2;
  padding: 40px;
  /* takes up 2/3 of the space */
  overflow-y: auto;
  background-color: #dcc6f0;
  /* background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%23f5ff6d' fill-opacity='0.52' fill-rule='evenodd'/%3E%3C/svg%3E"); */
  /* background-color: #dcc6f0;
  background-image: url("data:image/svg+xml,%3Csvg width='52' height='26' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23f5ff6d' fill-opacity='0.52'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E"); */
  /* transition: margin-top 0.3s; */
  /* Add this line for a smooth transition */
}

.news-bar {
  background-color: rgba(0, 0, 0, .7);
  height: 40px;
  /* Adjust as needed */
  width: 100%;
  overflow: hidden;
  /* Hide the overflowing text */
  position: relative;
  /* Relative positioning for the news text */
}

.news-text {
  color: yellow;
  font-family: "HighSpeed";
  white-space: nowrap;
  font-size: 26px;
  /* Prevent the text from wrapping to the next line */
  position: absolute;
  /* Absolute positioning relative to the news bar */
  /* top: 50%; */
  /* transform: translateY(-50%); */
  /* Center the text vertically */
  animation: scrollNews 110s linear infinite;
  animation-delay: 1s;
  /* Scroll animation */
}

@keyframes scrollNews {
  100% {
    transform: translateX(100%);
    /* Start from outside the right edge */
  }

  100% {
    transform: translateX(-100%);
    /* Move completely to the left */
  }
}

.App-header {
  font-size: calc(10px + 2vmin);
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.App-header h1 {
  font-family: "YoungSerif";
  font-size: 3em;
  /* outline the text */
  /* Adjust this value to make the name as big as you want */
  margin-bottom: 20px;
  /* padding-top: 50px; */
  margin-left: 20px;
  /* Spacing between the name and the links */
}

.App-navbar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.App-navbar li {
  /* margin: 10px 0; */
  /* Spacing between links */
  margin: 20px 20px;
  display: inline-block;
  /* Increase the spacing between links */
  font-size: 1.75em;
  /* bold the text */
  font-weight: bold;
}

.App-navbar a {
  /* text-decoration: none;
  color: inherit; */
  /* This will make the link color same as the parent's color */
  transition: color 0.3s ease;
  padding: 5px 10px;
  /* Smooth color transition */
}

.Genre-gallery {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 100px 5px;
  justify-content: space-evenly;
  /* padding:5rem; */
  /* gap: 2rem */
}

.genre-thumb {
  width: 400px;
  height: 450px;
  border: 1px solid #ccc;
  background: #fff;
  /* border-radius: 8px; */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  /* padding: 20px; */
  /* add a drop shadow */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

.slider-thumb {
  object-fit: cover;
}

.card-content {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  height: 100%;
  width: 100%;
  /* Ensures the content stretches to full height of the card */
}

.card-top-section {
  flex: 2;
  flex-direction: column;
  align-items: center;
}

.card-text {
  align-self: center;
  /* Centers the text horizontally */
  text-align: center;
  /* Centers the text inside the div */
  margin-bottom: 10px;
  /* You can adjust this if you feel the gap between the text and the button is too much */
}

.genre-thumb p {
  color: grey;
}

.card-image {
  width: 100%;
  /* height: 80%; */
  object-fit: cover;
  margin-bottom: 20px;
  /* Adds space below the image */
}

.genre-thumb-button {
  background-color: white;
  border: 2px solid black;
  font-size: 12px;
  border-radius: 20px;
  align-self: center;
  padding: 2px 15px;
  margin-bottom: 25px;
  /* Reduced padding to make button smaller */
  color: black;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.genre-thumb-button:hover {
  background-color: black;
  color: white;
}

.genre-thumb-button:focus {
  outline: none;
}

/* .genre-thumb h3 {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
} */

.App-gallery {
  width: 100%;
  height: 100%;
  border-style: solid;
  border-width: 3px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 5px;
}

/* /* .art-thumb {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin:5px;
} */
.artPopup {
  position: fixed;
  top: 12.5%;
  left: 12.5%;
  padding-top: 50px;
  width: 75%;
  height: 75%;
  overflow: auto;
  max-width: .5 vpw;
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); */
  /* add drop shadow */
  box-shadow: 0px 30px 100px rgba(0, 0, 0, 1);
}

.artPopup h3 {
  font-family: "Queenside";
  text-align: center;
  font-size: 2rem;
  font-weight: 200;
  margin-bottom: 0.5rem;
}

.artPopup p {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  padding: 2em;
}

.artPopupImages {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  overflow-x: scroll;
  margin: 1rem;
}

.artPopupImages img {
  object-fit: contain;
  width: 20rem;
  height: 20rem;
  margin: 1rem;
}

/* Remove grid styles from genre-page */
.genre-page {
  gap: 30px;
  visibility: hidden;
  /* Gap between rows and columns */
}


/* Individual artwork card */
.artwork {
  width: 100%;
  /* Ensure the artwork takes the full width of its container */
  min-height: 200px;
  /* Minimum height of the card */
  max-height: 800px;
  /* Maximum height of the card */
  overflow: hidden;
  /* This will hide any content that goes beyond the max-height */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  /* Optional: Adds a subtle shadow for a lifted effect */
  border-radius: 8px;
  /* Optional: Rounds the corners */
  transition: transform 0.3s;
  /* Optional: Smoothens the hover effect */
}

/* Image inside the artwork card */
.artwork img {
  width: 100%;
  /* Ensures the image takes the full width of the card */
  height: auto;
  /* Keeps the image aspect ratio */
  display: block;
  /* Removes any unwanted space below the image */

}

/* Optional: Slightly enlarges the card on hover for a nice effect */
.artwork:hover {
  transform: scale(1.05);
}

.artwork p {
  font-family: "Queenside";
  font-size: 24px;
  margin-top: 5px;
  font-weight: 500;
}

/* Popup styling */
/* .artPopup {
  max-width: .5 vpw;
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
} */

.artPopup h3 {
  margin-bottom: 10px;
}

.artPopup h4 {
  font-family: 'Open Sans', sans-serif;
  color: #666666;
  text-align: center;
}

.artPopupImages {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  /* Reduced gap between images */
  margin-bottom: 10px;
}

.artPopupImages img {
  max-width: 400px;
  height: auto;
  border-radius: 5px;
}

.artPopup p {
  font-size: 14px;
  color: #333;
}

.imagePopup {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

.imagePopup img {
  max-width: 90%;
  max-height: 90%;
  border-radius: 5px;
}

.fade-enter,
.fade-exit-done {
  opacity: 0;
}

.fade-enter-active,
.fade-exit {
  opacity: 1;
  transition: opacity 300ms;
}

.hoverPulse:hover {
  animation-name: pulse;
  animation-duration: 750ms;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.hamburger {
  display: none;
  /* Hidden by default */
  cursor: pointer;
}

.App-navbar.expanded {
  display: block;
}

@media (max-width: 768px) {
  .navbar {
    position: fixed;
    /* Make it fixed at the top */
    top: 0;
    left: 0;
    width: 100%;
    height: 21vh;
    /* Set to 20% of the viewport height */
    transition: top 0.3s;
    /* Smooth transition for showing/hiding */
  }

  .content {
    margin-top: 20vh;
    /* Push content down below the navbar */
  }

  .App-header h1 {
    font-size: 2.5em;
    margin-left: 0px;
    /* Adjust this value to make the name as big as you want */
    margin-bottom: 5px;
    /* Spacing between the name and the links */
  }

  .artPopup {
    padding: 5px;
  }

  .App-navbar li {
    /* margin: 10px 0; */
    /* Spacing between links */
    margin: 0px 20px;
    display: inline-block;
    /* Increase the spacing between links */
    font-size: 1.3em;
    /* bold the text */
    font-weight: bold;
  }

  .App-navbar a {
    padding: 2px 5px;
  }

  .card-text {
    margin-bottom: 3px;
    /* You can adjust this if you feel the gap between the text and the button is too much */
  }

  .card-image {
    margin-bottom: 10px;
    /* Adds space below the image */
  }

  .navbar-visible {
    transition: top 0.3s;
    top: 0;
  }

  .navbar-hidden {
    transition: top 0.3s;
    top: -100%;
    /* Adjust as needed to hide the navbar */
  }

  .content.no-navbar {
    margin-top: 0;
    /* No margin when the navbar is hidden */
    transition: margin-top 0.3s;
    /* Ensure the transition applies when the class changes */
  }


}