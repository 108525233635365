.about-container {
    /* text-align: center; */
    padding: 20px;
}

.about-container li {
    text-align: left;
    font-size: 24px;
}

.about-container h2 {
    text-align: left;
    font-size: 30px;
}

.emb {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    height: 40em;
}

/* Style the profile image */
.profile-image {
    width: 450px;
    /* Adjust the size as needed */
    height: 450px;
    /* Adjust the size as needed */
    /* border-radius: 50%; */
    /* Make it round */
    object-fit: cover;
    /* Maintain aspect ratio */
    margin: 0 auto 20px;
    /* Center the image and add some space below */
    display: block;
}

/* Style the name */
.name {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin: 10px 0;
}

/* Style the social media icons */
.social-media-icons {
    margin-top: 10px;
    text-align: center;
}

/* Style individual social media icons */
.social-media-icons .icon {
    display: inline-block;
    margin: 0 10px;
}

/* Style the description */
.description {
    font-size: 24px;
    line-height: 1.5;
    margin: 20px 0;
}

@media (max-width: 768px) {
    .profile-image {
        width: 300px;
        /* Adjust the size as needed */
        height: 300px;
        /* Adjust the size as needed */
    }

    .description {
        font-size: 20px;
        margin: 10px 0;
    }

    .about-container {
        /* text-align: center; */
        padding: 10px;
    }
}